exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-index-js": () => import("./../../../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-templates-article-template-js-content-file-path-articles-decision-intelligence-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/opt/build/repo/articles/decision-intelligence.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-articles-decision-intelligence-mdx" */),
  "component---src-templates-article-template-js-content-file-path-articles-ml-ux-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/opt/build/repo/articles/ML-UX.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-articles-ml-ux-mdx" */),
  "component---src-templates-project-template-js-content-file-path-projects-ada-mdx": () => import("./../../../src/templates/project-template.js?__contentFilePath=/opt/build/repo/projects/ada.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-projects-ada-mdx" */),
  "component---src-templates-project-template-js-content-file-path-projects-aipm-mdx": () => import("./../../../src/templates/project-template.js?__contentFilePath=/opt/build/repo/projects/aipm.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-projects-aipm-mdx" */),
  "component---src-templates-project-template-js-content-file-path-projects-clothesline-mdx": () => import("./../../../src/templates/project-template.js?__contentFilePath=/opt/build/repo/projects/clothesline.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-projects-clothesline-mdx" */),
  "component---src-templates-project-template-js-content-file-path-projects-fdh-mdx": () => import("./../../../src/templates/project-template.js?__contentFilePath=/opt/build/repo/projects/fdh.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-projects-fdh-mdx" */),
  "component---src-templates-project-template-js-content-file-path-projects-manhattan-mdx": () => import("./../../../src/templates/project-template.js?__contentFilePath=/opt/build/repo/projects/manhattan.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-projects-manhattan-mdx" */),
  "component---src-templates-project-template-js-content-file-path-projects-montrosa-mdx": () => import("./../../../src/templates/project-template.js?__contentFilePath=/opt/build/repo/projects/montrosa.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-projects-montrosa-mdx" */),
  "component---src-templates-project-template-js-content-file-path-projects-musicology-mdx": () => import("./../../../src/templates/project-template.js?__contentFilePath=/opt/build/repo/projects/musicology.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-projects-musicology-mdx" */),
  "component---src-templates-project-template-js-content-file-path-projects-spotify-podcasts-mdx": () => import("./../../../src/templates/project-template.js?__contentFilePath=/opt/build/repo/projects/spotify_podcasts.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-projects-spotify-podcasts-mdx" */),
  "component---src-templates-project-template-js-content-file-path-projects-venn-mdx": () => import("./../../../src/templates/project-template.js?__contentFilePath=/opt/build/repo/projects/venn.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-projects-venn-mdx" */),
  "component---src-templates-project-template-js-content-file-path-projects-voice-mdx": () => import("./../../../src/templates/project-template.js?__contentFilePath=/opt/build/repo/projects/voice.mdx" /* webpackChunkName: "component---src-templates-project-template-js-content-file-path-projects-voice-mdx" */)
}

